import React, { useEffect, useState } from 'react'
import '../../BlogItemPage.css';

import icon_viber from '../../../../assets/icons/VIBER.webp';
import icon_facebook from '../../../../assets/icons/FACEBOOK.webp';
import icon_messenger from '../../../../assets/icons/MESSENGER.webp';
import icon_instagram from '../../../../assets/icons/INSTAGRAM.webp';
import icon_email from '../../../../assets/icons/EMAIL.webp';
import icon_copy from '../../../../assets/icons/COPY LINK.webp';
import { Dropdown } from 'antd';
import { CopyToClipboard } from '../../../../hooks/helpers';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {ClockCircleOutlined} from '@ant-design/icons';

import type { MenuProps } from 'antd';
import { useBreadCrumbsUpdateContext } from '../../../Content/AffiliateLayers/Context/BreadCrumbsContext';

import BlogPicture from '../../../../assets/blogSaarpor/dekorativne-zidne-obloge.webp';
import BlogPicture2 from '../../../../assets/blogSaarpor/dekorativne-led-lajsne.webp';
import BlogPicture3 from '../../../../assets/blogSaarpor/dekorativne-zidne-obloge-imitacija-kamena.webp';
import { Helmet } from 'react-helmet';

const banner_img_link = 'https://thumbs.dreamstime.com/b/group-industrial-workers-banner-blank-isolated-over-white-background-207139483.webp'
const img_link = 'https://alfatim.rs/wp-content/uploads/2021/12/Moler-featured-slika.webp';
const p_text = '';
function BlogTemplateTwoSaarpor() {

    const routeHistoryUpdate = useBreadCrumbsUpdateContext();
    
    useEffect(() => {
      routeHistoryUpdate(['Početna', 'Blog', 'Transformišite svoj prostor: Magija Saarpor dekorativnih lajsni i obloga']);
    }, []);
    
const shareButtonItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'viber://forward?text='+ encodeURIComponent("Pogledajte ovaj proizvod na zvanicnom sajtu Grometa"+ " " + window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_viber}></img>
          Viber
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://www.facebook.com/sharer/sharer.php?u='+ (window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_facebook}></img>
          Facebook
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'fb-messenger://share/?link= ' + (window.location.href)}
        >
          <img
            className='imgShareButtonDropDownIcon'
            src={icon_messenger}
          ></img>
          Messenger
        </a>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <a
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       href='https://www.luohanacademy.com'
    //     >
    //       <img
    //         className='imgShareButtonDropDownIcon'
    //         src={icon_instagram}
    //       ></img>
    //       Instagram
    //     </a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={"mailto:?subject=Pogledajte ovaj blog na zvanicnom sajtu Grometa&body="+window.location.href}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_email}></img>
          Email
        </a>
      ),
    },
    {
      key: '6',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => CopyToClipboard(window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_copy}></img>
          Kopiraj link
        </a>
      ),
    },
  ];
    const [showShare, setShowShare] = useState<boolean>(false);
  
    const handleSaarporClick = () => {
      localStorage.setItem("potkategorije", "DEKORATIVNE OBLOGE")
      window.location.href = '/proizvodi#filteri=DEKORATIVNE OBLOGE&stranica=2'
    }
    return (
    <div className='container divBlogPostItemContainerContainer'>
      <Helmet>
          <title>{"Transformišite svoj prostor: Magija Saarpor dekorativnih lajsni i obloga"}</title>
          <meta name="description" content={"Poštovani saradnici, sa zadovoljstvom vam predstavljamo deo naše ponude - izložbene police"} />
         
      </Helmet>
        <div className='divBlogPostItemContainer container'>
            <h1 className='divBlogPostItemTitle'>Transformišite svoj prostor: Magija Saarpor dekorativnih lajsni i obloga</h1>
            <div className="blogpost-bottomLabel"><ClockCircleOutlined style={{marginLeft: "8px", paddingLeft:"5px"}}></ClockCircleOutlined><label style={{marginLeft: "5px"}}>{"4 minuta čitanja"}</label>{','} {'03.04.2024.'} </div>
            
           
            
            {/* <h1 className='divBlogPostItemh1'>Uvod</h1> */}
            <p className='pBlogPostItemText'>
                {"Estetika i funkcionalnost igraju ključnu ulogu u unapređenju svakog prostora. U savremenom dizajnu enterijera, proizvodi poput dekorativnih lajsni i obloga postaju sve popularniji. One ne samo da donose sofisticiran izgled, već nude i dodatnu vrednost u obliku poboljšanja toplotne i zvučne izolacije. U nastavku vam predstavljamo Saarpor proizvode i načine na koje oni mogu unaprediti Vaš enterijer. "}
            </p>
            {/* <h2 className='divBlogPostItemh2'>Podnaslov</h2> */}
            <p className='pBlogPostItemText'>
                {'Dizajn enterijera predstavlja umetnost i nauku stvaranja funkcionalnih i estetski privlačnih prostora. Ovaj složeni proces obuhvata različite aspekte, uključujući boje, materijale, osvetljenje, raspored dekorativnih elemenata, sve sa ciljem stvaranja uravnoteženog i harmoničnog okruženja. Najbolji dizajn enterijera uzima u obzir kako prostor funkcioniše, ali i kako utiče na ljude koji borave u njemu. Zaista, kroz odabir boja, tekstura i oblika, dizajn enterijera može uticati na raspoloženje i osećanja onoga ko boravi u tom prostoru.'}
            </p>
            {/* slika */}
            <div className='divBlogItemPageImgBanner container' style={{maxWidth: "960px", maxHeight: "400px"}}>
                <img src={BlogPicture} style={{maxWidth: "960px", maxHeight: "400px"}}></img>
            </div>

            <h2 className='divBlogPostItemh2'>  {'Posebno osvetljenje koje pružaju Saarpor dekorativne led lajsne'}</h2>

            <p className='pBlogPostItemText'>
              {'Dekorativne led lajsne kombinuju funkcionalnost i lepotu. Napravljene su od ekstrudiranog polistirena, materijala koji se ističe svojom čvrstoćom i lakoćom obrade. Ove lajsne nude jedinstvenu estetiku u kombinaciji sa LED trakom koja proizvodi diskretnu, indirektnu rasvetu. Bilo da želite da naglasite određeni deo prostora, stvorite topao i prijatan ambijent ili čak dajete iluziju proširenja prostora, LED trake u kombinaciji sa dekorativnim lajsnama mogu biti savršen izbor. Ova rasveta ne samo da obogaćuje vizuelni doživljaj prostora, već i stvara toplu i prijatnu atmosferu.'}
            </p>

           {/* slika */}
           <div className='divBlogItemPageImgBanner container' style={{maxWidth: "960px", maxHeight: "400px"}}>
                <img src={BlogPicture2} style={{maxWidth: "960px", maxHeight: "400px"}}></img>
            </div>
            <h2 className='divBlogPostItemh2'>  {'Sakrijte nesavršenosti'}</h2>

            <p className='pBlogPostItemText'>
                {'Ugaone lajsne takođe izrađene od ekstrudiranog polistirena predstavljaju još jedan način da dodate sofisticiranost svom enterijeru. One omogućavaju da se lako i efikasno sakriju nesavršenosti na mestima spoja zida i plafona, pružajući prostoru završni i profinjeni izgled. Pored toga, mogu se postaviti i na drugim mestima kao ukrasni elementi, dodajući estetsku vrednost prostoru. One takođe mogu biti korisne za sakrivanje kablova ili cevi, održavajući prostor urednim i elegantnim. Dekorativne lajsne su sofisticirane i elegantne, pružaju diskretan način da se uvede tekstura i boja u prostor. Njihova jednostavnost i funkcionalnost čine ih izuzetno prilagodljivima, dok su različite mogućnosti dizajna gotovo beskrajne.'}
            </p>

            <h2 className='divBlogPostItemh2'>  {'Dajte prostoru treću dimenziju'}</h2> 
            <p className='pBlogPostItemText'>
                {'Dajte prostoru treću dimenziju 3D dekorativne zidne i plafonske obloge nude upečatljivo i inovativno rešenje za oblaganje zidova i plafona. Njihov jedinstveni 3D dizajn donosi dubinu i karakter svakom prostoru, stvarajući vizuelno zanimljive efekte. Pored toga, ove obloge poboljšavaju toplotnu izolaciju, čineći vaš dom energetski efikasnijim. Izrađene su od ekstrudiranog polistirena, čime se garantuje trajnost i izdržljivost.'}
            </p>

            <h2 className='divBlogPostItemh2'>  {'Zašto je važno unaprediti svoj enterijer?'}</h2> 
            <p className='pBlogPostItemText'>
                {"Enterijeri svih vrsta, bilo da se radi o domovima, poslovnim prostorima ili javnim zdanjima, zahtevaju pažljivu pažnju na detalje. Male promene mogu imati veliki uticaj na izgled i atmosferu prostora. Upravo u ovoj oblasti, dekorativne lajsne i obloge mogu odigrati ključnu ulogu. Ovi elementi, iako naizgled neupadljivi, imaju mogućnost da transformišu enterijer, poboljšavajući njegovu estetiku i funkcionalnost. Primena dekorativnih lajsni i obloga je jednostavna i efikasna. Bez obzira na veličinu ili oblik prostora, ovi elementi mogu biti prilagođeni vašim specifičnim potrebama odnosno vašem prostoru. Osim toga, oni mogu biti obojeni u različite boje kako bi se uklopili u postojeću šemu boja vašeg enterijera."}
            </p>

            <p className='pBlogPostItemText'>
                {"Na kraju krajeva, dizajn enterijera je najvažnija stavka kada je reč o stvaranju prostora koji ne samo da izgleda dobro, već je i udoban i funkcionalan. Saarpor proizvodi pružaju priliku da se oba cilja postignu, unapređujući estetiku i funkcionalnost vašeg prostora. Bez obzira da li želite da osvetlite određenu oblast, dodate teksturu zidovima ili jednostavno dodate sofisticirani dodir vašem enterijeru, ovi elementi mogu biti idealno rešenje. Uprkos svim ovim prednostima, upotreba dekorativnih lajsni i obloga nije komplikovana. Upravo suprotno, instalacija ovih elemenata je jednostavna i efikasna. Bez obzira na veličinu ili oblik prostora, mogu biti prilagođene da odgovaraju vašim specifičnim potrebama."}
            </p>

            {/* slika */}
            <div className='divBlogItemPageImgBanner container' style={{maxWidth: "960px", maxHeight: "400px"}}>
                <img src={BlogPicture3} style={{maxWidth: "960px", maxHeight: "400px"}}></img>
            </div>

            <p className='pBlogPostItemText'>
                {'Osim lepog izgleda, dekorativne obloge se lako postavljaju što ih čini jako praktičnima. Dovoljno je naneti akrilni silikon ili lepak za stiropor lajsne i obloge na oblogu i prisloniti uza zid odnosno plafon. Osigurati laganim pritiskom ruke i voditi računa da nema prostora između obloga. Obloge se lako seku skalpelom pa tako prilagođavaju vašem zidu odnosno plafonu. Lajsne na isti način zalepiti uz ivice, prethodno krajeve lajsni iseći po dijagonali kako bi se pravilno uklopile.'}
            </p>


            <a  onClick={() => handleSaarporClick()} style={{textDecoration: "underline", cursor: "pointer"}}>Pogledajte Saarpor proizvode:</a>
            <br></br><br></br>
            
            <div className='productPageShareIconLabel'>
                  <Dropdown
                    menu={{ items: [...shareButtonItems] }}
                    placement='top'
                    arrow
                    open={showShare}
                  >
                    <button
                      className='productPageShareIconLabelButton'
                      onClick={() => setShowShare(!showShare)}
                    >
                      Podeli
                      <LazyLoadImage
                        effect='blur'
                        className='productPageShareIcon'
                        src='https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png'
                      />
                    </button>
                  </Dropdown>
            </div>
            <br></br>
        {/* <label><b> #police #policezaizlaganje #prodaja #proizvodi #artikli</b></label> */}
        {/* <br></br> */}
        <a href='/novo' style={{textDecoration: "underline"}}>Pogledajte našu najnoviju ponudu proizvoda</a>
            <br></br>    
        </div>

    </div>
  )
}

export default BlogTemplateTwoSaarpor