import React, { useEffect, useState } from 'react';
import BlogPost from './BlogPost';
import './Blog.css';
import { Button } from 'antd';
import { useBreadCrumbsUpdateContext } from '../Content/AffiliateLayers/Context/BreadCrumbsContext';

import blog0 from '../../assets/blogOne/slika1.webp';
import blog1 from '../../assets/blogtwo/slika8.webp';
import blog2 from '../../assets/blogThree/police-za-izlaganje.webp';
import blogSaarpor3 from '../../assets/blogSaarpor/dekorativne-zidne-obloge.webp';
import blogSiniat3 from '../../assets/blogSiniat/montiranje-gips-karton-ploca.webp';
import blogFiveTricks from '../../assets/BlogFiveAppTricks/aplikacije-drustvene-mreze.jpg.webp';
import blogMineralWool from '../../assets/BlogMineralWool/ursa-vuna.webp';
import blogHydroisolation from  '../../assets/BlogHydroisolation/izolacija1.jpg.webp';





import blog0_thumbnails from '../../assets/blogOne/blog1.webp';

import blog1_thumbnails from '../../assets/blogtwo/blog2.webp';
import blog2_thumbnails from '../../assets/blogThree/blog3.webp';
import blogSaarpor3_thumbnails from  '../../assets/blogSaarpor/dekorativne-led-lajsne.webp'
import blogSiniat3_thumbnails from '../../assets/blogSiniat/malterisanje-gips-karton-ploce.webp';
import blogFiveTricks_thumbnails from '../../assets/BlogFiveAppTricks/facebook-whatsapp-komunikacija.jpg.webp';
import blogMineralWool_thumbnails from '../../assets/BlogMineralWool/vuna.webp';
import blogHydroisolation_thumbnails from  '../../assets/BlogHydroisolation/folija.jpg.webp';

import { Helmet } from 'react-helmet';



export const minuti_citanja = [
  3,
  4,
  4,
  4,
  4,
  4,
  4,
  2,
]
const thumbnails = [
  blogHydroisolation_thumbnails,
  blogMineralWool,
  blogFiveTricks_thumbnails,
  blogSiniat3_thumbnails,
  blogSaarpor3_thumbnails,
  blog1_thumbnails,
  blog0_thumbnails,
  blog2_thumbnails,
]

export const latestNews = [
  blogHydroisolation,
  blogMineralWool,
  blogFiveTricks,
  blogSiniat3,
  blogSaarpor3,
  blog1,
  blog0,
  blog2,
];

export const authors = ['Gromet'];
export const categories = [
  'Hidroizolacija',
  'Saveti i trikovi',
  'Saveti i trikovi',
  'Suva gradnja',
  'Novo u ponudi',
  'Ekologija',
  'Gradjevinarstvo',
  'Enterijer',
  // 'Gradjevinarstvo',
  // 'Novinarstvo',
  // 'Dizajn',
  // 'Enterijer',
  // 'Alati',
  // 'Materijal',
  // 'Masine',
  // 'Skice',
];
export const dates = [
  '18.06.2024',
  '22.05.2024',
  '29.04.2024',
  '19.04.2024',
  '03.04.2024',
  '11.06.2023',
  '06.06.2023',
  '05.06.2023',
  // '25.02.2023',
  // '26.02.2023',
  // '27.02.2023',
  // '28.02.2023',
  // '01.03.2023',
  // '02.03.2023',
  // '03.03.2023',
];
export const titles = [
  'Važnost hidroizolacije: Ključ za komfor i dugovečnost vašeg doma',
  'Skriveni potencijal mineralne vune: Vaš vodič za efikasnu izolaciju',
  '5 korisnih trikova za aplikacije koje svakodnevno koristite',
  'Budućnost gradnje: Održivost i praktičnost gips-karton ploča',
  'Transformišite svoj prostor: Magija Saarpor dekorativnih lajsni i obloga',
  'Top 10 najboljih ekoloških materijala za održivu gradnju',
  '5 razloga da suvom gradnjom zamenite tradicionalnu',
  'Unapredite izlaganje proizvoda uz naše nove police za izlaganje',
  // 'Exploring the Versatility of Concrete: Innovative Uses and Designs',
  // 'The Pros and Cons of Using Natural Stone in Your Construction Project',
  // 'Breaking Down the Benefits of Metal Roofing for Commercial Buildings',
  // 'The Evolution of Sustainable Building Materials: What to Look for in Green Construction',
  // 'Why Insulated Concrete Forms are a Game-Changer in Modern Home Building',
  // 'From Traditional to Modern: A Guide to Choosing the Right Brick for Your Project',
];
export const href = [
  "/blog/Važnost-hidroizolacije:-Ključ-za-komfor-i-dugovečnost-vašeg-doma",
  "/blog/Skriveni-potencijal-mineralne-vune:-Vaš-vodič-za-efikasnu-izolaciju",
  "/blog/5-korisnih-trikova-za-aplikacije-koje-svakodnevno-koristite",
  "/blog/Budućnost-gradnje:-Održivost-i-praktičnost-gips-karton-ploča",
  "/blog/Transformišite-svoj-prostor:-Magija-Saarpor-dekorativnih-lajsni-i-obloga",
  "/blog/Top-10-najboljih-ekoloških-materijala-za-održivu-gradnju",
  "/blog/5-razloga-da-suvom-gradnjom-zamenite-tradicionalnu",
  '/blog/Unapredite-izlaganje-proizvoda-uz-naše-nove-police-za-izlaganje',
  // "",
  // "",
  // "",
  // "",
  // "",
  // "",
];

export const contents = [
  "Voda je izvor života koji nam omogućava da opstanemo, ali kada je reč o našim domovima, voda može biti neprijatelj broj jedan. Vlaga koja prodire...",
  "Jeste li znali da većina domova i poslovnih prostora gubi veliku količinu energije zbog loše izolacije zidova i krovova? I koliko puta ste se našli u situaciji da osetno gubite koncentraciju tokom rada zbog neželjenih zvukova iz okoline?",
  "Da li ste znali da postoje trikovi koji vam mogu pomoći da iskoristite maksimum od socijalnih platformi koje koristite svakoga dana? Socijaln...",
  "Gips-karton ploče su materijali sastavljeni od gipsa i obloge od papira. Služe za oblaganje zidova, izradu unutrašnjih pregrada i spuštanje plafona. Gips je prirodni mineral koji se stvrdnjava kada se pomeša sa vodom, a papirna obloga pruža stabilnost i zaštitu. Ovaj spoj stvara ploče koje su izdržljive, a istovremeno lagane i jednostavne za rukovanje. Inovativne, sa mnogo prednosti kako u lakoći korišćenja tako i u materijalu, održivosti, ceni i drugo, gips-karton ploče postaju neizostavan deo moderne arhitekture.",
  "Estetika i funkcionalnost igraju ključnu ulogu u unapređenju svakog prostora. U savremenom dizajnu enterijera, proizvodi poput dekorativnih...",
  "Ekološka svest postala je sveprisutna tema koja je u poslednjoj deceniji izuzetno dobila na značaju. Građevinski sektor je jedan od najuticajnijih kada je u pitanju ekološki uticaj na sredinu, potrošnja resursa ili emisija štetnih gasova. Iz ovog razloga je veoma važno da što veći broj ljudi koji posluju u okviru ove industrije, prepozna važnost zaštite životne sredine i u svoj posao implementira ekološki prihvatljivije materijale.",
  "Suva gradnja je tehnologija građenja koja se izvodi bez upotrebe vode, gline, cementa i drugih klasičnih građevinskih materijala. Umesto toga, koristi se širok spektar modernih građevinskih materijala, kao što su gips-karton ploče, paneli od OSB-a, drvo, čelični profili i drugi materijali koji se mogu lako montirati i demontirati. Ovaj proces izgradnje omogućava brzu i ekonomičnu gradnju, sa manje otpada, manjom potrošnjom energije i manjim zagađenjem okoline u poređenju sa tradicionalnom gradnjom. Suva gradnja se često koristi za izgradnju unutrašnjih pregrada, plafona, izolacije, kao i za izgradnju potpunih kuća i drugih vrsta građevina.",
  "Dragi partneri, Predstavljamo Vam najnoviji dodatak našoj ponudi - police za izlaganje. Ova usluga ima za cilj da vam pomogne da maksimalno iskoristite prostor u vašim prodavnicama, poboljšate prezentaciju naših proizvoda i povećate prodaju.",
  

  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
  // "In one general sense, philosophy is associated with wisdom, intellectual culture and a search for knowledge. In that sense, all cultures...",
];
const text_popularno = 'Popularno';
const text_poslednje = 'Poslednje';

function Blog() {
  const routeHistoryUpdate = useBreadCrumbsUpdateContext();
  useEffect(() => {
    routeHistoryUpdate(['Početna', 'Blog']);
  }, []);

  const [panelShown, setPanelShown] = useState(text_popularno);
  return (
    <div className="container">
      <Helmet>
          <title>{"Blog"}</title>
          <meta name="description" content={"Dragi partneri, Predstavljamo Vam najnoviji dodatak našoj ponudi. Ekološka svest i gradjevinski materijal"} />
      </Helmet>
      <div className="BlogPagePageHeaderTitle page-header__title">
        <h1>Blog</h1>
      </div>
      <div className="divBlogContainer">
        <div className="divFlexBlogPostsContainer">
          {latestNews.map((news, index) => (
            <BlogPost
              key={index}
              {...{
                image: news,
                isSmall: false, 
                title: titles[index],
                category: categories[index],
                author: 'Gromet',
                date: dates[index],
                readTime: `${minuti_citanja[index]} minuta čitanja`,
                href: href[index],
                content: contents[index]
              }}
            ></BlogPost>
          ))}
        </div>

        <div className="divBlogRightContainer">
          <div className="block block-sidebar block-sidebar--position--end">

            <div className="block-sidebar__item blogPopularBlogNew">
              <Button
              className={  panelShown === text_popularno ? 'blogCardSelected' : ''}
                onClick={() => setPanelShown(text_popularno)}
              >
                {text_popularno}
              </Button>
              <Button
                className={  panelShown === text_poslednje ? 'blogCardSelected' : ''}
                onClick={() => setPanelShown(text_poslednje)}
              >
                {text_poslednje}
              </Button>
              <div className="widget-aboutus widget">
                <div
                  className="blogPopularnoLista"
                  style={{
                    display: panelShown === text_popularno ? 'flex' : 'none',
                  }}
                >
                  {thumbnails.map((news, index) => {
                    if (index % 2 === 0)
                      return (
                        <BlogPost
                          key={index}
                          {...{
                            image: news,
                            isSmall: true, 
                            title: titles[index],
                            category: categories[index],
                            author: '',
                            date: dates[index],
                            readTime: `${minuti_citanja[index]} minuta čitanja`,
                            href: href[index]
                          }}
                        ></BlogPost>
                      );
                  })}
                </div>
                <div
                  className="blogPoslednjeLista"
                  style={{
                    display: panelShown === text_poslednje ? 'flex' : 'none',
                  }}
                >
                  {thumbnails.slice(0,3).map((news, index) => {
                      return (
                        <BlogPost
                          key={index}
                          {...{
                            image: news,
                            isSmall: true, 
                            title: titles[index],
                            category: categories[index],
                            author: '',
                            date: dates[index],
                            readTime: `${minuti_citanja[index]} minuta čitanja`,
                            href: href[index]
                          }}
                        ></BlogPost>
                      );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
