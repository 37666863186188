import React, { useEffect, useState } from 'react'
import '../../BlogItemPage.css';

import icon_viber from '../../../../assets/icons/VIBER.webp';
import icon_facebook from '../../../../assets/icons/FACEBOOK.webp';
import icon_messenger from '../../../../assets/icons/MESSENGER.webp';
import icon_instagram from '../../../../assets/icons/INSTAGRAM.webp';
import icon_email from '../../../../assets/icons/EMAIL.webp';
import icon_copy from '../../../../assets/icons/COPY LINK.webp';
import { Dropdown } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockCircleOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';

import Slika1 from '../../../../assets/BlogMineralWool/ursa-vuna.webp';
import Slika2 from '../../../../assets/BlogMineralWool/vuna.webp';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from '../../../../hooks/helpers';
import { useBreadCrumbsUpdateContext } from '../../../Content/AffiliateLayers/Context/BreadCrumbsContext';

const banner_img_link = 'https://thumbs.dreamstime.com/b/group-industrial-workers-banner-blank-isolated-over-white-background-207139483.webp'
const img_link = 'https://alfatim.rs/wp-content/uploads/2021/12/Moler-featured-slika.webp';
const p_text = 'Ekološka svest postala je sveprisutna tema koja je u poslednjoj deceniji izuzetno dobila na značaju. Građevinski sektor je jedan od najuticajnijih kada je u pitanju ekološki uticaj na sredinu, potrošnja resursa ili emisija štetnih gasova. Iz ovog razloga je veoma važno da što veći broj ljudi koji posluju u okviru ove industrije, prepozna važnost zaštite životne sredine i u svoj posao implementira ekološki prihvatljivije materijale.'
const p_podnaslov = `Postoji dosta razlika između ove dve vrste materijala, ali neke od najistaknutijih su sledeće:`

function
BlogTemplateThreeMineralWool() {

  const routeHistoryUpdate = useBreadCrumbsUpdateContext();

  useEffect(() => {
    routeHistoryUpdate(['Početna', 'Blog', 'Skriveni potencijal mineralne vune: Vaš vodič za efikasnu izolaciju']);
  }, []);

  const shareButtonItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'viber://forward?text=' + encodeURIComponent("Pogledajte ovaj proizvod na zvanicnom sajtu Grometa" + " " + window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_viber}></img>
          Viber
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://www.facebook.com/sharer/sharer.php?u=' + (window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_facebook}></img>
          Facebook
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'fb-messenger://share/?link= ' + (window.location.href)}
        >
          <img
            className='imgShareButtonDropDownIcon'
            src={icon_messenger}
          ></img>
          Messenger
        </a>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <a
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       href='https://www.luohanacademy.com'
    //     >
    //       <img
    //         className='imgShareButtonDropDownIcon'
    //         src={icon_instagram}
    //       ></img>
    //       Instagram
    //     </a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={"mailto:?subject=Pogledajte ovaj blog na zvanicnom sajtu Grometa&body=" + window.location.href}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_email}></img>
          Email
        </a>
      ),
    },
    {
      key: '6',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => CopyToClipboard(window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_copy}></img>
          Kopiraj link
        </a>
      ),
    },
  ];
  const [showShare, setShowShare] = useState<boolean>(false);

  const handleAlatiClick = () => {
    localStorage.setItem("potkategorije", "PRATEĆI GIPS PROGRAM,Alati-skalpeli-noževi-testere-žičane četke-makaze-pištolji-heftalice-merni alati i libele-mikseri-gleterice-špahtle-mistrije-četke-valjci-ostali alati")
    window.location.href = '/proizvodi#filteri=PRATEĆI GIPS PROGRAM,Alati&stranica=1'
  }

  return (
    <div className='container divBlogPostItemContainerContainer'>
      <Helmet>
          <title>{"Skriveni potencijal mineralne vune: Vaš vodič za efikasnu izolaciju"}</title>
          <meta name="description" content={"Jeste li znali da većina domova i poslovnih prostora gubi veliku količinu energije zbog loše izolacije zidova i krovova? I koliko puta ste se našli u situaciji da osetno gubite koncentraciju tokom rada zbog neželjenih zvukova iz okoline? Postoji jedno jednostavno rešenje za obe situacije – mineralna vuna. Dobra izolacija prostora u kom boravite je veoma važna jer, osim što će smanjiti račune za grejanje za 30%, osiguraće vam i  prijatnu i toplu atmosferu za rad i odmor."} />
      </Helmet>
      <div className='divBlogPostItemContainer container'>
        <h1 className='divBlogPostItemTitle'>Skriveni potencijal mineralne vune: Vaš vodič za efikasnu izolaciju</h1>
        <div className="blogpost-bottomLabel"><ClockCircleOutlined style={{ marginLeft: "8px", paddingLeft: "5px" }}></ClockCircleOutlined><label style={{ marginLeft: "5px" }}>{"4 minuta citanja"}</label>{','} {'19.04.2024.'} </div>
        <label>
        Jeste li znali da većina domova i poslovnih prostora gubi veliku količinu energije zbog loše izolacije zidova i krovova? I koliko puta ste se našli u situaciji da osetno gubite koncentraciju tokom rada zbog neželjenih zvukova iz okoline?
        Postoji jedno jednostavno rešenje za obe situacije – mineralna vuna. Dobra izolacija prostora u kom boravite je veoma važna jer, osim što će smanjiti račune za grejanje za 30%, osiguraće vam i  prijatnu i toplu atmosferu za rad i odmor.
        </label><br></br> <br></br>
        {/* montiranje gips karton ploca */}
        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika1}></img>
        </div>

        {/* <h1 className='divBlogPostItemh1'>Uvod</h1> */}
        {/* <p className='pBlogPostItemText'>
          {p_text}
        </p> */}
        <h2 className='divBlogPostItemh2'>5 saveta za odabir najbolje mineralne vune</h2>
  
        <p className='pBlogPostItemText'>
          <ul>
            <li>
              <b>Napravite razliku između mineralne i staklene vune</b> <br></br>
              Mineralna (kamena) i staklena vuna nikako nisu ista stvar. Iako su to dva glavna tipa izolacije od mineralnih vlakana, imaju neke razlike u sastavu i karakteristikama.
•Staklena vuna se pravi od recikliranog stakla, uglavnom staklenih boca.
•Mineralna vuna se pravi od prirodnih minerala poput bazalta ili dijabaza.
            </li><br></br>
            <li>
              <b>Obratite pažnju na toplotnu provodljivost materijala</b> <br></br>
              Toplota se manje gubi kroz materijale sa manjom toplotnom provodljivošću. Mineralna vuna URSA i XPS su takvi materijali, što znači da su dobri za očuvanje toplote u zgradama. Ovo pomaže da se smanje računi za grejanje i hlađenje.
Kada se zgrada dobro izoluje, unutrašnje površine ostaju toplije u zimskim mesecima, što smanjuje rizik od vlage i buđi. Takođe, ljudi se osećaju udobnije. U letnjim mesecima, dobra izolacija sprečava da toplota prodre unutra, što smanjuje potrebu za klima uređajima.
            </li><br></br>
            <li>
              <b>Obratite pažnju na veličinu i orijentaciju prozora i ventilacije</b> <br></br>
              Da bi se smanjila potrošnja energije, važno je imati pravilno postavljene prozore koji maksimalno koriste sunčevu energiju za zagrevanje zimi i efikasne sisteme zaštite od sunca kako bi se sprečilo prekomerno zagrevanje leti. Takođe, dobra ventilacija pomaže u održavanju udobnosti prostora bez potrebe za klimatizacijom. Ovi faktori, zajedno sa kvalitetnom toplotnom izolacijom, mogu značajno smanjiti potrošnju energije u zgradama.
            </li><br></br>
            <li>
              <b>Odaberite vunu koja je pogodna za zvučnu izolaciju</b> <br></br>
              Ursa TWF Fono mineralna vuna nudi ne samo odličnu toplotnu izolaciju već i izuzetnu sposobnost apsorpcije zvuka zbog svoje elastičnosti. Zbog toga se URSA proizvodi mogu koristiti za smanjenje zvuka između prostorija ili spoljne buke.<br/><br/>
            </li><br/>
            <li>
              <b> Birajte materiale koji imaju pozitivan ekološki uticaj na okolinu</b> <br></br>
              Očuvanje životne sredine je, u današnje vreme, posebno važna tema. Stoga treba voditi računa o materijalima koje koristimo i njihovom uticaju na okolinu.
              Za proizvodnju URSA izolacionih vuna velikim delom se koriste reciklirani materijali, čime se doprinosi očuvanju prirodnih resursa. Mineralna vuna sadrži više od 70% reciklata, dok je ostatak prirodni mineralni materijal poput kvarcnog peska.
            </li><br></br>
          </ul>
        </p>

        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika2}></img>
        </div>

        <h2 className='divBlogPostItemh2'></h2>
        <p className='pBlogPostItemText'>
         
        </p>

         <label>
           Na sledećem videu možete detaljnije čuti  svojstvima mineralne vune
        </label> <br></br><br></br>

        <iframe width="100%"
         height="315"
         src="https://www.youtube.com/embed/HvztYK04Okw"
         title="Proizvodnja mineralne staklene vune URSA"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
         ></iframe>

        <p className='pBlogPostItemText'>
          Kako maksimalno iskoristiti potencijal ove vune?
        </p>

        <p className='pBlogPostItemText'>
          Kada ste saznali sve o pogodnostima ove vrste izolacije, u nastavku vam dajemo osnovne savete kako da maksimalno iskoristite prednosti mineralne vune:<br/><br/>
          1. Pravilno postavljanje: Prilikom postavljanja mineralne vune, važno je da je pravilno postavite kako bi se maksimalno iskoristile njene izolacione sposobnosti. Pazite da nema praznina ili razmaka između panela.<br/><br/>
          2. Zaptivanje spojeva: Da biste osigurali da nema propuštanja toplote, zaptivajte spojeve između panela ili izolacionih materijala trakama za zaptivanje.<br/><br/>
          3. Upotreba u kombinaciji sa drugim materijalima: Mineralnu vunu možete kombinovati sa drugim materijalima kao što su <a href='https://www.gromet.rs/proizvod/krovna-folija'>parna brana</a> ili <a href='https://www.gromet.rs/proizvod/gips-karton-ploca-nida-standard'>gips karton ploče</a> kako biste poboljšali izolacione karakteristike vaše građevinske konstrukcije.<br/><br/>
          4. Prilagodite debljinu: Debljina mineralne vune može biti prilagođena prema potrebama i zahtevima vašeg objekta kako bi se postigla optimalna izolacija.<br/><br/>
          5. Održavanje: Redovno održavanje izolacije je ključno za očuvanje njenih izolacionih sposobnosti. Proveravajte da li su spojevi i zaptivke i dalje u dobrom stanju kako bi se sprečilo curenje toplote.<br/><br/>
          6. Konsultujte stručnjake: Ako niste sigurni kako najbolje iskoristiti mineralnu vunu u vašem projektu, konsultujte se sa stručnjacima za izolaciju koji će vam pružiti savete i preporuke za najbolje rezultate.<br/><br/>
        </p>
        {/* <br></br>
        <br></br> */}
        <a href='https://www.ursa.rs/' style={{textDecoration: "underline"}}>
          Detaljnije o svim vrstama vune možete pogledati na sajtu naših partnera
        </a>
        <br></br>
        <br></br>

        <div className='productPageShareIconLabel'>
          <Dropdown
            menu={{ items: [...shareButtonItems] }}
            placement='top'
            arrow
            open={showShare}
          >
            <button
              className='productPageShareIconLabelButton'
              onClick={() => setShowShare(!showShare)}
            >
              Podeli
              <LazyLoadImage
                effect='blur'
                className='productPageShareIcon'
                src='https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png'
              />
            </button>
          </Dropdown>
        </div>
        <br></br>
        {/* <label><b>#suvagradnja #tradicionalnagradnja #gips-kartonploče #prednostisuvegradnje #ekologija #ekološkaprednost #budućnostgradnje</b></label> */}
      </div>

    </div>
  )
}

export default BlogTemplateThreeMineralWool;