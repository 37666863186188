import React, { useEffect, useState } from 'react'
import '../../BlogItemPage.css';

import icon_viber from '../../../../assets/icons/VIBER.webp';
import icon_facebook from '../../../../assets/icons/FACEBOOK.webp';
import icon_messenger from '../../../../assets/icons/MESSENGER.webp';
import icon_instagram from '../../../../assets/icons/INSTAGRAM.webp';
import icon_email from '../../../../assets/icons/EMAIL.webp';
import icon_copy from '../../../../assets/icons/COPY LINK.webp';
import { Dropdown } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockCircleOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';

import Slika1 from '../../../../assets/blogSiniat/montiranje-gips-karton-ploca.webp';
import Slika2 from '../../../../assets/blogSiniat/malterisanje-gips-karton-ploce.webp';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from '../../../../hooks/helpers';
import { useBreadCrumbsUpdateContext } from '../../../Content/AffiliateLayers/Context/BreadCrumbsContext';

const banner_img_link = 'https://thumbs.dreamstime.com/b/group-industrial-workers-banner-blank-isolated-over-white-background-207139483.webp'
const img_link = 'https://alfatim.rs/wp-content/uploads/2021/12/Moler-featured-slika.webp';
const p_text = 'Ekološka svest postala je sveprisutna tema koja je u poslednjoj deceniji izuzetno dobila na značaju. Građevinski sektor je jedan od najuticajnijih kada je u pitanju ekološki uticaj na sredinu, potrošnja resursa ili emisija štetnih gasova. Iz ovog razloga je veoma važno da što veći broj ljudi koji posluju u okviru ove industrije, prepozna važnost zaštite životne sredine i u svoj posao implementira ekološki prihvatljivije materijale.'
const p_podnaslov = `Postoji dosta razlika između ove dve vrste materijala, ali neke od najistaknutijih su sledeće:`

function
BlogTemplateThreeSiniat() {

  const routeHistoryUpdate = useBreadCrumbsUpdateContext();

  useEffect(() => {
    routeHistoryUpdate(['Početna', 'Blog', 'Budućnost gradnje: Održivost i praktičnost gips-karton ploča']);
  }, []);

  const shareButtonItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'viber://forward?text=' + encodeURIComponent("Pogledajte ovaj proizvod na zvanicnom sajtu Grometa" + " " + window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_viber}></img>
          Viber
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://www.facebook.com/sharer/sharer.php?u=' + (window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_facebook}></img>
          Facebook
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'fb-messenger://share/?link= ' + (window.location.href)}
        >
          <img
            className='imgShareButtonDropDownIcon'
            src={icon_messenger}
          ></img>
          Messenger
        </a>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <a
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       href='https://www.luohanacademy.com'
    //     >
    //       <img
    //         className='imgShareButtonDropDownIcon'
    //         src={icon_instagram}
    //       ></img>
    //       Instagram
    //     </a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={"mailto:?subject=Pogledajte ovaj blog na zvanicnom sajtu Grometa&body=" + window.location.href}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_email}></img>
          Email
        </a>
      ),
    },
    {
      key: '6',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => CopyToClipboard(window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_copy}></img>
          Kopiraj link
        </a>
      ),
    },
  ];
  const [showShare, setShowShare] = useState<boolean>(false);

  const handleAlatiClick = () => {
    localStorage.setItem("potkategorije", "PRATEĆI GIPS PROGRAM,Alati-skalpeli-noževi-testere-žičane četke-makaze-pištolji-heftalice-merni alati i libele-mikseri-gleterice-špahtle-mistrije-četke-valjci-ostali alati")
    window.location.href = '/proizvodi#filteri=PRATEĆI GIPS PROGRAM,Alati&stranica=1'
  }

  return (
    <div className='container divBlogPostItemContainerContainer'>
      <Helmet>
          <title>{"Budućnost gradnje: Održivost i praktičnost gips-karton ploča"}</title>
          <meta name="description" content={"Gips-karton ploče su materijali sastavljeni od gipsa i obloge od papira. Služe za oblaganje zidova, izradu unutrašnjih pregrada i spuštanje plafona. Gips je prirodni mineral koji se stvrdnjava kada se pomeša sa vodom, a papirna obloga pruža stabilnost i zaštitu. Ovaj spoj stvara ploče koje su izdržljive, a istovremeno lagane i jednostavne za rukovanje. Inovativne, sa mnogo prednosti kako u lakoći korišćenja tako i u materijalu, održivosti, ceni i drugo, gips-karton ploče postaju neizostavan deo moderne arhitekture."} />
      </Helmet>
      <div className='divBlogPostItemContainer container'>
        <h1 className='divBlogPostItemTitle'>Budućnost gradnje: Održivost i praktičnost gips-karton ploča</h1>
        <div className="blogpost-bottomLabel"><ClockCircleOutlined style={{ marginLeft: "8px", paddingLeft: "5px" }}></ClockCircleOutlined><label style={{ marginLeft: "5px" }}>{"4 minuta citanja"}</label>{','} {'19.04.2024.'} </div>
        <label>
            Gips-karton ploče su materijali sastavljeni od gipsa i obloge od papira. Služe za oblaganje zidova, izradu unutrašnjih pregrada i spuštanje plafona.
            Gips je prirodni mineral koji se stvrdnjava kada se pomeša sa vodom, a papirna obloga pruža stabilnost i zaštitu. Ovaj spoj stvara ploče koje su izdržljive, a istovremeno lagane i jednostavne za rukovanje. Inovativne, sa mnogo prednosti kako u lakoći korišćenja tako i u materijalu, održivosti, ceni i drugo, gips-karton ploče postaju neizostavan deo moderne arhitekture.
        </label><br></br> <br></br>
        {/* montiranje gips karton ploca */}
        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika1}></img>
        </div>

        {/* <h1 className='divBlogPostItemh1'>Uvod</h1> */}
        {/* <p className='pBlogPostItemText'>
          {p_text}
        </p> */}
        <h2 className='divBlogPostItemh2'>Zašto su gips-karton ploče tako popularne?</h2>
  
        <p className='pBlogPostItemText'>
          <ul>
            <li>
              <b>Brza i jednostavna instalacija:</b> <br></br>
              Kada gradite ili renovirate svoj dom, vreme je često presudan faktor. Jedna od glavnih prednosti ovih ploča je po brza i jednostavna instalacija. Njihova lakoća znači da se mogu postaviti brže nego tradicionalni zidni materijali, što smanjuje vreme izgradnje i doprinosi uštedi troškova rada.
            </li><br></br>
            <li>
              <b>Prilagodljive različitim arhitektonskim potrebama</b> <br></br>
              Gips-karton ploče su posebno prilagodljive i mogu se seći, oblikovati i ugraditi u različite oblike kako bi se zadovoljile vaše arhitektonske potrebe. Bez obzira da li kreirate pregradni zid, spušteni plafon ili dekorativni oblik, gips-karton ploče će se prilagoditi vašoj viziji, samo je potrebno odabrati pravu vrstu ploča u skladu sa vašim zahtevima i idejama.
            </li><br></br>
            <li>
              <b>Dobra zvučna i toplotna izolacija</b> <br></br>
              Gips-karton ploče pružiće vam odličnu zvučnu i toplotnu izolaciju. To može pomoći u stvaranju ugodnijeg i štedljivog životnog prostora.
            </li><br></br>
            <li>
              <b>Niža cena:</b> <br></br>
              Kada uporedimo cenu gips-karton ploča s drugim građevinskim materijalima, primetićemo da su ove ploče pristupačnije što predstavlja još jednu bitnu prednost.
            </li><br></br>
          </ul>
        </p>

        {/* <label>
          Uverite se u jednostavnost procesa suve gradnje:
        </label> <br></br><br></br>
        <iframe width="100%"
         height="315"
         src="https://www.youtube.com/embed/D2IH4NPT2zs"
         title="YouTube video player"
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
         ></iframe> */}
        

        <h2 className='divBlogPostItemh2'>Vrste gips-karton ploča</h2>
        <p className='pBlogPostItemText'>
            Postoje različite vrste gips-karton ploča koje su prilagođene različitim potrebama i zahtevima u građevinskim projektima. Evo nekoliko najčešćih vrsta gips-karton ploča:
            <ul style={{listStyle:'none'}}>
            <li>
            •Standardne gips-karton ploče: Ovo su osnovne gips-karton ploče koje se koriste za opšte oblaganje zidova, plafona i unutrašnjih pregrada. One imaju dobru čvrstoću i jednostavne su za rukovanje i instalaciju. Pružaju ravne i glatke površine za završne radove poput nanošenja boje, tapeta i drugih dekorativnih premaza.
            </li>
            <li>
            •Vlagootporne gips-karton ploče: Osim gipsa i papira, sadrže hidrofobne aditive koji ih čine otpornijima na vlagu, stoga su pogodne za upotrebu u prostorijama s povećanom vlagom, poput kupatila, kuhinja i slično. Sprečavaju propadanje i oštećenja ploča uzrokovana vlagom, čime se produžuje njihov vek trajanja i očuvava estetika prostora.
            </li>
            <li>
            •Vodootporne gips-karton ploče: Posebno dizajnirane da pruže izuzetnu otpornost na vodu i vlagu. Izrađene su s hidrofobnim aditivima koji smanjuju apsorpciju vode i čine ih otpornim na izloženost vlagi, kiši, snegu, dakle promenama vremena i različitim vlažnim uslovima. Koriste se za eksterijerne primene, kao spoljne obloge za spoljne zidove ili u mokrim prostorima gde je visoka izloženost vodi. Vodootporne ploče često se primjenjuju na područjima koja će biti izložena direktnoj vodi ili potencijalnom potapanju, kao što su fasade, temelji ili bazeni.
            </li>
            <li>    
                •Vatrootporne gips-karton ploče: Ove ploče su posebno konstruisane da pruže dodatnu vatrootpornost. U slučaju požara, ove ploče se neće lako zapaliti i pomažu u sprečavanju širenja vatre i osiguravaju viši nivo sigurnosti u objektu.
            </li>
            <li>
                •Zvučno-izolacione (akustične) gips-karton ploče: Ove ploče imaju poboljšana zvučna izolacijska svojstva koja smanjuju prolaz zvuka kroz zidove i plafone. Korisne su jer poboljšavaju zvučnu udobnost prostora osiguravajući privatnost i miran ambijent.
            </li>
            </ul>
        </p>


        <h2 className='divBlogPostItemh2'>Nezamenljive u savremenoj gradnji</h2>
        <p className='pBlogPostItemText'>
        Gips-karton ploče su izuzetno svestran i praktičan građevinski materijal koji je postao nezamenljiv u savremenoj gradnji i uređenju interijera. Njihova brza instalacija, lagana težina i prilagodljivost čine ih omiljenim izborom za mnoge građevinske radove. Bez obzira da li renovirate svoj dom ili gradite novi, gips-karton ploče su vredan dodatak koji će poboljšati funkcionalnost i estetiku vašeg doma.
        </p>
        
        {/* malterisanje gips karton ploce */}
        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika2}></img>
        </div>

        

        <p className='pBlogPostItemText'>
        Suva gradnja se sve više prepoznaje kao budućnost građevinske industrije. Njeni brojni benefiti čine je idealnom za moderno doba. Osim toga, u eri kada su promene klimatskih uslova i održivost ključni globalni izazovi, suva gradnja pruža rešenja koja su ne samo praktična i ekonomična, već i ekološki odgovorna. Kako se tehnologija i inovacije nastavljaju razvijati, mogućnosti i prednosti koje suva gradnja pruža samo će se proširiti, što je čini ključnim igračem u oblikovanju budućnosti građevinske industrije.
        </p>
        {/* <br></br>
        <br></br> */}
        <a href='/proizvodi#filteri=PRATE%C4%86I%20GIPS%20PROGRAM&stranica=1 ' style={{textDecoration: "underline"}}>
          Pogledajte Siniat gips-karton ploče iz naše ponude
        </a>
        <br></br>
        <br></br>

        <div className='productPageShareIconLabel'>
          <Dropdown
            menu={{ items: [...shareButtonItems] }}
            placement='top'
            arrow
            open={showShare}
          >
            <button
              className='productPageShareIconLabelButton'
              onClick={() => setShowShare(!showShare)}
            >
              Podeli
              <LazyLoadImage
                effect='blur'
                className='productPageShareIcon'
                src='https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png'
              />
            </button>
          </Dropdown>
        </div>
        <br></br>
        {/* <label><b>#suvagradnja #tradicionalnagradnja #gips-kartonploče #prednostisuvegradnje #ekologija #ekološkaprednost #budućnostgradnje</b></label> */}
      </div>

    </div>
  )
}

export default BlogTemplateThreeSiniat;