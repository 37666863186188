import React, { useEffect, useState } from 'react'
import '../../BlogItemPage.css';

import icon_viber from '../../../../assets/icons/VIBER.webp';
import icon_facebook from '../../../../assets/icons/FACEBOOK.webp';
import icon_messenger from '../../../../assets/icons/MESSENGER.webp';
import icon_instagram from '../../../../assets/icons/INSTAGRAM.webp';
import icon_email from '../../../../assets/icons/EMAIL.webp';
import icon_copy from '../../../../assets/icons/COPY LINK.webp';
import { Dropdown } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockCircleOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';

import Slika8 from '../../../../assets/BlogHydroisolation/izolacija1.jpg.webp';
import Slika7 from '../../../../assets/BlogHydroisolation/folija.jpg.webp'
import { Helmet } from 'react-helmet';
import { useBreadCrumbsUpdateContext } from '../../../Content/AffiliateLayers/Context/BreadCrumbsContext';
import { CopyToClipboard } from '../../../../hooks/helpers';

function
BlogTemplateAHydroisolation() {

  const routeHistoryUpdate = useBreadCrumbsUpdateContext();

  useEffect(() => {
    routeHistoryUpdate(['Početna', 'Blog', 'Važnost hidroizolacije: Ključ za komfor i dugovečnost vašeg doma']);
  }, []);

  const shareButtonItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'viber://forward?text=' + encodeURIComponent("Pogledajte ovaj proizvod na zvanicnom sajtu Grometa" + " " + window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_viber}></img>
          Viber
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://www.facebook.com/sharer/sharer.php?u=' + (window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_facebook}></img>
          Facebook
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'fb-messenger://share/?link= ' + (window.location.href)}
        >
          <img
            className='imgShareButtonDropDownIcon'
            src={icon_messenger}
          ></img>
          Messenger
        </a>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <a
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       href='https://www.luohanacademy.com'
    //     >
    //       <img
    //         className='imgShareButtonDropDownIcon'
    //         src={icon_instagram}
    //       ></img>
    //       Instagram
    //     </a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={"mailto:?subject=Pogledajte ovaj blog na zvanicnom sajtu Grometa&body=" + window.location.href}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_email}></img>
          Email
        </a>
      ),
    },
    {
      key: '6',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => CopyToClipboard(window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_copy}></img>
          Kopiraj link
        </a>
      ),
    },
  ];
  const [showShare, setShowShare] = useState<boolean>(false);

  const handlePistoljiClick = () => {
    localStorage.setItem("potkategorije", "Alati-pištolji")
    window.location.href = '/proizvodi#filteri=Alati&stranica=1'
  }

  return (
    <div className='container divBlogPostItemContainerContainer'>
      <Helmet>
          <title>{"Važnost hidroizolacije: Ključ za komfor i dugovečnost vašeg doma"}</title>
          <meta name="description" content={
            "Voda je izvor života koji nam omogućava da opstanemo, ali kada je reč o našim domovima, voda može biti neprijatelj broj jedan." +
            "Vlaga koja prodire kroz zidove, podove ili krovove može uzrokovati ozbiljna oštećenja, stvaranje buđi i ugroziti strukturu zgrade. "} />
      </Helmet>
      <div className='divBlogPostItemContainer container'>
        <h1 className='divBlogPostItemTitle'>Važnost hidroizolacije: Ključ za komfor i dugovečnost vašeg doma</h1>
        <div className="blogpost-bottomLabel"><ClockCircleOutlined style={{ marginLeft: "8px", paddingLeft: "5px" }}></ClockCircleOutlined><label style={{ marginLeft: "5px" }}>{"3 minuta citanja"}</label>{','} {'18.06.2024.'} </div>

        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika8}></img>
        </div>

        {/* <h1 className='divBlogPostItemh1'>Uvod</h1> */}
        <p className='pBlogPostItemText'>
        Voda je izvor života koji nam omogućava da opstanemo, ali kada je reč o našim domovima, voda može biti neprijatelj broj jedan. Vlaga koja prodire kroz zidove, podove ili krovove može uzrokovati ozbiljna oštećenja, stvaranje buđi i ugroziti strukturu zgrade. 
        Upravo zbog toga je hidroizolacija ključna preventiva ovakvih neprilika. Bez adekvatne hidroizolacije, dom može biti podložan brojnim problemima koji mogu ugroziti njegovu sigurnost, udobnost i vrednost. U ovom blogu istražićemo zašto je hidroizolacija od vitalnog značaja za očuvanje vašeg doma i kako pravilno primeniti ove tehnike kako biste osigurali suv, zdrav i siguran životni prostor.
        </p>
        <h2 className='divBlogPostItemh2'>Koji sve tipovi hidroizolacije postoje?</h2>
        <p className='pBlogPostItemText'>
          <ul>
            <li><b>	Hidroizolacione membrane:</b> Ključni elementi u zaštiti građevinskih objekata od vlage i vode. Ove membrane su obično napravljene od specijalnih materijala kao što su bitumen, poliuretan, PVC ili EPDM (etilen-propilen-dien-monomer), i postavljaju se na površine koje su podložne prodoru vode, kao što su temelji, podovi podruma, krovovi, zidovi, ili podovi kupatila.</li>
            <li> <b>Premazi za hidroizolaciju:</b> To su tečni materijali koji se nanose na površinu kako bi se stvorio vodootporni sloj. Ovi premazi su široko korišćeni za zaštitu različitih građevinskih elemenata od prodora vlage i vode.
            <br/>
            Postoje različite vrste hidroizolacionih premaza, uključujući <a href='https://gromet.rs/proizvod/cepasta-folija-20m '>bitumenske premaze</a>,  poliuretanske premaze, silikonske premaze i epoksidne premaze. Svaka vrsta premaza ima svoje karakteristike i prednosti u zavisnosti od specifičnih potreba i uslova primene.
            </li>
            <li><b><a href=' https://gromet.rs/proizvod/bimplast-bitumenska-traka-v3 '>Hidroizolacione trake:</a></b> Ovo su fleksibilne trake koje se koriste za zaptivanje spojeva, pukotina i otvora na površinama koje su podložne prodoru vode. One su obično napravljene od gume, bitumena, polimera ili drugih vodootpornih materijala i mogu biti samolepljive ili zahtevati dodatno lepljenje. Hidroizolacione trake su posebno efikasne za zaptivanje spojeva između različitih materijala kao što su beton, metal, drvo ili plastika.</li>
            <li><b>Hidroizolacioni paneli:</b> Oni se uglavnom prave od različitih materijala, kao što su poliuretan, staklena vuna ili ekspandirani polistiren, koji se koriste za stvaranje vodonepropusnog sloja na površinama koje su podložne prodoru vode. Ovi paneli se često koriste za izolaciju zidova, podova, krovova ili temelja radi zaštite od vlage i vode.</li>
            <li><b><a href='https://gromet.rs/proizvod/cepasta-folija-20m '>Hidroizolacione folije:</a></b> Plastične folije koje se koriste za oblaganje zidova i podova radi zaštite od vlage. Ove folije su obično napravljene od polietilena, polipropilena, PVC-a ili drugih sličnih materijala koji su vodootporni i otporni na habanje.</li>
          </ul>
        </p>




        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika7}></img>
        </div>

        <h2 className='divBlogPostItemh2'>5 ključnih koraka pre odabira prave vrste hidroizolacije</h2>
        <p className='pBlogPostItemText'>
          <ol>
            <li><b>Analizirajte potrebe projekta:</b> Razmislite o tome koji delovi objekta su najviše izloženi vodi i vlazi, kao i o specifičnim zahtevima hidroizolacije za te delove. Na primer, podrumi zahtevaju drugačiji pristup od krovova ili zidova.</li>
            <li><b>Istražite različite vrste hidroizolacije: </b> U ovom blogu ste saznali za nekoliko različitih vrsta hidroizolacije, ali postoji mnogo više od toga i svaka od njih ima svoje prednosti i mane, pa je važno istražiti koje će najbolje odgovarati vašim potrebama.</li>
            <li><b>Procenite karakteristike materijala:</b>  Razmislite o trajnosti, otpornosti na habanje, UV stabilnosti, kao i mogućnosti rastezanja i skupljanja bez oštećenja. Ove karakteristike mogu uticati na efikasnost hidroizolacije tokom vremena i vek trajanja.</li>
            <li><b>Konsultujte stručnjake: </b>  Posavetujte se sa stručnjacima u oblasti građevinarstva ili hidroizolacije kako biste dobili preporuke i savete koji će vam pomoći da odaberete najbolju vrstu hidroizolacije za vaš projekat.</li>
            <li><b>Uzmite u obzir budžet i željeni vek trajanja: </b>  Razmislite o tome koliko novca ste spremni da uložite u hidroizolaciju i koliko dugo želite da hidroizolacija traje. Neke vrste hidroizolacije mogu biti skuplje u početnoj investiciji, ali mogu imati duži vek trajanja i manje zahteva za održavanjem, što može biti isplativije na duže staze.</li>
          </ol>
        </p>

        <p className='pBlogPostItemText'>
        Investiranje u kvalitetnu hidroizolaciju može vam uštedeti novac i probleme u budućnosti, čuvajući vaš objekat od potencijalnih oštećenja i produžavajući njegov vek trajanja. Važno je shvatiti da je hidroizolacija važna investicija u sigurnost i održivost vaše građevine, te da pravilno odabrana hidroizolacija može pružiti dugotrajan mir i zaštitu.
        </p>
    
        <a href='/proizvodi#filteri=HIDROIZOLACIJA&stranica=1' style={{textDecoration: "underline"}}>
          Pogledajte naše proizvode za hidroizolaciju 
        </a>
        <br></br>
        <br></br>

        <div className='productPageShareIconLabel'>
          <Dropdown
            menu={{ items: [...shareButtonItems] }}
            placement='top'
            arrow
            open={showShare}
          >
            <button
              className='productPageShareIconLabelButton'
              onClick={() => setShowShare(!showShare)}
            >
              Podeli
              <LazyLoadImage
                effect='blur'
                className='productPageShareIcon'
                src='https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png'
              />
            </button>
          </Dropdown>
        </div>
        <br></br>
      </div>

    </div>
  )
}

export default BlogTemplateAHydroisolation;