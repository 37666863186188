import React, { useEffect, useState } from 'react'
import '../.././BlogItemPage.css';

import icon_viber from '../../../../assets/icons/VIBER.webp';
import icon_facebook from '../../../../assets/icons/FACEBOOK.webp';
import icon_messenger from '../../../../assets/icons/MESSENGER.webp';
import icon_instagram from '../../../../assets/icons/INSTAGRAM.webp';
import icon_email from '../../../../assets/icons/EMAIL.webp';
import icon_copy from '../../../../assets/icons/COPY LINK.webp';
import { Dropdown } from 'antd';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockCircleOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';

import Slika7 from '../../assets/blogOne/slika7.webp';
import Slika10 from '../../assets/blogOne/slika10.webp';
import Slika1 from '../../../../assets/BlogFiveAppTricks/aplikacije-drustvene-mreze.jpg.webp';
import Slika2 from '../../../../assets/BlogFiveAppTricks/facebook-whatsapp-komunikacija.jpg.webp';
import Slika3 from '../../../../assets/BlogFiveAppTricks/instagram-aplikacija.jpg.webp';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from '../../../../hooks/helpers';
import { useBreadCrumbsUpdateContext } from '../../../Content/AffiliateLayers/Context/BreadCrumbsContext';

const banner_img_link = 'https://thumbs.dreamstime.com/b/group-industrial-workers-banner-blank-isolated-over-white-background-207139483.webp'
const img_link = 'https://alfatim.rs/wp-content/uploads/2021/12/Moler-featured-slika.webp';
const p_text = 'Ekološka svest postala je sveprisutna tema koja je u poslednjoj deceniji izuzetno dobila na značaju. Građevinski sektor je jedan od najuticajnijih kada je u pitanju ekološki uticaj na sredinu, potrošnja resursa ili emisija štetnih gasova. Iz ovog razloga je veoma važno da što veći broj ljudi koji posluju u okviru ove industrije, prepozna važnost zaštite životne sredine i u svoj posao implementira ekološki prihvatljivije materijale.'
const p_podnaslov = `Postoji dosta razlika između ove dve vrste materijala, ali neke od najistaknutijih su sledeće:`

function
BlogTemplateFiveAppTricks() {

  const routeHistoryUpdate = useBreadCrumbsUpdateContext();

  useEffect(() => {
    routeHistoryUpdate(['Početna', 'Blog', '5 korisnih trikova za aplikacije koje svakodnevno koristite']);
  }, []);

  const shareButtonItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'viber://forward?text=' + encodeURIComponent("Pogledajte ovaj proizvod na zvanicnom sajtu Grometa" + " " + window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_viber}></img>
          Viber
        </a>
      ),
    },
    {
      key: '2',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'https://www.facebook.com/sharer/sharer.php?u=' + (window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_facebook}></img>
          Facebook
        </a>
      ),
    },
    {
      key: '3',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={'fb-messenger://share/?link= ' + (window.location.href)}
        >
          <img
            className='imgShareButtonDropDownIcon'
            src={icon_messenger}
          ></img>
          Messenger
        </a>
      ),
    },
    // {
    //   key: '4',
    //   label: (
    //     <a
    //       target='_blank'
    //       rel='noopener noreferrer'
    //       href='https://www.luohanacademy.com'
    //     >
    //       <img
    //         className='imgShareButtonDropDownIcon'
    //         src={icon_instagram}
    //       ></img>
    //       Instagram
    //     </a>
    //   ),
    // },
    {
      key: '4',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          href={"mailto:?subject=Pogledajte ovaj blog na zvanicnom sajtu Grometa&body=" + window.location.href}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_email}></img>
          Email
        </a>
      ),
    },
    {
      key: '6',
      label: (
        <a
          target='_blank'
          rel='noopener noreferrer'
          onClick={() => CopyToClipboard(window.location.href)}
        >
          <img className='imgShareButtonDropDownIcon' src={icon_copy}></img>
          Kopiraj link
        </a>
      ),
    },
  ];
  const [showShare, setShowShare] = useState<boolean>(false);

  const handleAlatiClick = () => {
    localStorage.setItem("potkategorije", "PRATEĆI GIPS PROGRAM,Alati-skalpeli-noževi-testere-žičane četke-makaze-pištolji-heftalice-merni alati i libele-mikseri-gleterice-špahtle-mistrije-četke-valjci-ostali alati")
    window.location.href = '/proizvodi#filteri=PRATEĆI GIPS PROGRAM,Alati&stranica=1'
  }

  return (
    <div className='container divBlogPostItemContainerContainer'>
      <Helmet>
          <title>{"5 korisnih trikova za aplikacije koje svakodnevno koristite"}</title>
          <meta name="description" content={"Izgradnja zahteva vreme, rad i posvećenost, ali da li ste ikada razmišljali da li postoji rešenje koje bi olakšalo ceo proces"} />
      </Helmet>
      <div className='divBlogPostItemContainer container'>
        <h1 className='divBlogPostItemTitle'>5 korisnih trikova za aplikacije koje svakodnevno koristite</h1>
        <div className="blogpost-bottomLabel"><ClockCircleOutlined style={{ marginLeft: "8px", paddingLeft: "5px" }}></ClockCircleOutlined><label style={{ marginLeft: "5px" }}>{"4 minuta citanja"}</label>{','} {'29.04.2024.'} </div>
        <label>Da li ste znali da postoje trikovi koji vam mogu pomoći da iskoristite maksimum od socijalnih platformi koje koristite svakoga dana?
Socijalne platforme služe nam kako bismo održavali kontakte, delili informacije i unapredili naše poslovanje. Predstavićemo vam 5 korisnih trikova za socijalne platforme koji će vaše prisustvo na njima podići na viši nivo.</label><br></br> <br></br>
        <div className='divBlogItemPageImgBanner container'>
          <img src={Slika1}></img>
        </div>

        {/* <h1 className='divBlogPostItemh1'>Uvod</h1> */}
        {/* <p className='pBlogPostItemText'>
          {p_text}
        </p> */}
        <h2 className='divBlogPostItemh2'>Facebook: Facebook privatna grupa</h2>
        <p className='pBlogPostItemText'>
        Facebook grupa je moćan alat za povezivanje sa ljudima koji imaju slične interese i ciljeve. Ova funkcionalnost može biti korisna za poslovne svrhe. Možete napraviti privatnu grupu na Facebooku za svoje kupce, gde ćete moći da delite informacije, vesti o proizvodima, odgovarate na pitanja i angažujete se sa članovima grupe na efikasan način. Grupa pruža prostor za zajedničko diskutovanje i izgradnju odnosa sa vašom ciljnom grupom. 
        </p>

        <div className='divBlogItemPageImgBanner'>
          <img src={Slika2} alt='slika merdevina' style={{maxHeight: "500px"}}></img>
        </div>
      
        
        {/* <div className='divBlogItemPageImages'>
                <img src={img_link}></img>
                <img src={img_link}></img>
                <img src={img_link}></img>
            </div> */}
        <h2 className='divBlogPostItemh2'>Viber: Skener QR koda</h2>
        <p className='pBlogPostItemText'>
        Viber je jedna od najpopularnijih aplikacija za razmenu poruka. Međutim, mnogi korisnici ne znaju jedan veoma koristan alat koji Viber pruža - skenera QR koda. 
          <ol>
            <li>
              <b>QR kod (Quick Response kod)</b> <br></br>
              je dvodimenzionalni štampani ili digitalni kod koji se može skenirati pomoću pametnog telefona ili skenera QR koda koji Viber sadrži. Glavna svrha QR koda je brzo prenošenje informacija. Kada se QR kod skenira, može se automatski otvoriti veza, prikazati tekstualna poruka, pokrenuti aplikacija, prikazati elektronska vizitka, video ili bilo koja druga akcija koja je programirana u QR kodu.
            </li><br></br>
            <li>
            Skener QR koda omogućava vam brzo i jednostavno povezivanje sa drugim korisnicima, poslovnim nalozima ili grupama. Umesto da ručno unosite brojeve ili tražite grupe, jednostavno koristite skener QR koda i uštedite vreme.
            </li><br></br>
          </ol>
        </p>
      
        

        <h2 className='divBlogPostItemh2'>Instagram: Prodajni post</h2>
        <p className='pBlogPostItemText'>
        Instagram je postao vodeća platforma za vizuelno predstavljanje brendova i proizvoda. Jedan koristan trik za poslovanje na Instagramu je korišćenje funkcije "Shoppable post" (prodajni post). Ova funkcija vam omogućava da označite proizvode na fotografijama i povežete ih sa svojom online prodavnicom. To olakšava korisnicima da otkriju i kupuju proizvode direktno preko Instagrama, pružajući vam mogućnost da efikasno promovišete proizvode i povećate prodaju.
        </p>
        
        <div className='divBlogItemPageImgBanner'>
          <img src={Slika3} alt='slika merdevina' style={{maxHeight: "500px"}}></img>
        </div>

        {/* <div className='divBlogItemPageImgBanner' style={{display:"flex", justifyContent:"center"}}>
          <img src={Slika7} style={{width:"unset", maxHeight: window.innerWidth > 500 ? "400px" : "250px"}}></img>
          <img src={Slika10} style={{width:"unset", maxHeight: window.innerWidth > 500 ? "400px" : "250px"}}></img>
        </div> */}

        <h2 className='divBlogPostItemh2'>Whatsapp: Etikete za sortiranje poruka</h2>
        <p className='pBlogPostItemText'>
        Whatsapp je jedna od najpopularnijih aplikacija za slanje poruka i komunikaciju sa kontaktima. Jedan od korisnih trikova u Whatsappu je korišćenje etiketa. Etikete vam omogućavaju da organizujete i označite poruke prema određenim kategorijama. Ovo je posebno korisno kada želite da pravite pregled ili pretragu određenih vrsta poruka, kao što su narudžbine, važne informacije ili pitanja od kupaca.
        </p>
        


        <h2 className='divBlogPostItemh2'>Messenger: Automatski odgovori</h2>

        <p className='pBlogPostItemText'>
        Facebook Messenger je popularna platforma za razmenu poruka koja se često koristi za poslovnu komunikaciju. Jedan od korisnih trikova u Messengeru je postavljanje automatskih odgovora. Ova funkcija vam omogućava da unapred postavite automatske odgovore kako biste brzo i efikasno reagovali na poruke korisnika. Ovo je posebno korisno ako očekujete veliki broj poruka ili želite da obavestite korisnike o radnom vremenu ili često postavljanim pitanjima.

        Korišćenje ovih korisnih trikova na socijalnim platformama kao što su Viber, Facebook, Instagram, Whatsapp i Messenger može vam pomoći da unapredite svoje poslovanje, efikasno komunicirate sa korisnicima i ostvarite veći uspeh. Iskoristite ove trikove i uživajte u svim prednostima koje vam ove platforme pružaju!
        </p>
        {/* <br></br>
        <br></br> */}
        <a href='/blog' style={{textDecoration: "underline"}}>
          Pogledajte naše ostale blogove:
        </a>
        <br></br>
        <br></br>

        <div className='productPageShareIconLabel'>
          <Dropdown
            menu={{ items: [...shareButtonItems] }}
            placement='top'
            arrow
            open={showShare}
          >
            <button
              className='productPageShareIconLabelButton'
              onClick={() => setShowShare(!showShare)}
            >
              Podeli
              <LazyLoadImage
                effect='blur'
                className='productPageShareIcon'
                src='https://www.freeiconspng.com/thumbs/www-icon/vector-illustration-of-simple-share-icon--public-domain-vectors-23.png'
              />
            </button>
          </Dropdown>
        </div>
        <br></br>
        {/* <label><b>#suvagradnja #tradicionalnagradnja #gips-kartonploče #prednostisuvegradnje #ekologija #ekološkaprednost #budućnostgradnje</b></label> */}
      </div>

    </div>
  )
}

export default BlogTemplateFiveAppTricks